import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { getBrandList } from "../../services/super-admin.service";
import { useNavigate } from "react-router-dom";

import AddIcon from "../../assets/images/add_icon.svg";
import UpdateIcon from "../../assets/images/update_icon.svg";
import ViewIcon from "../../assets/images/view_icon.svg";
import DeleteIcon from "../../assets/images/delete_icon.svg";

import CashBackPriority from "./CashBackPriority";
import CreateLocation from "./CreateLocationForm";
import Editcashbackrule from "./Editcashbackrule";
import ViewLoaction from "./ViewLocation";
import setting from "../../assets/images/setting_icon.svg";
import ArrowleftIcon from "../../assets/images/arrowleft_icon.svg";
import PaginatedTable from "../common/PaginatedTable";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import CloseIcon from "../../assets/images/close_icon.svg";
import SelectdateIcon from "../../assets/images/selectdate_icon.svg";
import { formatCurrency } from "../../utils/helpers/helper";
// import { getBrandList } from "../../services/super-admin.service";
import { convertToISOString } from "../../services/common.service";

import EditBrandForm from "./EditBrandForm";
// import ViewCashbank from "./ViewCashbank";
import {
  getLocation,
  // updateCashBackRules,
  // removeCashBackRules
} from "../../services/Location.service";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectRole } from "../../store/role/role.selector";

const LocationManagement = ({ backFunc, countdata }) => {
  console.log("countdata::: ", countdata);

  const { id } = useParams();
  console.log("id::: ", id);
  const [createForm, setCreateForm] = useState(false);
  const [brandTable, setBrandTable] = useState(true);
  const [createcashproform, setcashproform] = useState(false);
  const [cashbackview, setcashbackview] = useState(false);
  const [bool, setBool] = useState(false);

  const [Ediform, setEdiform] = useState(false);
  const [brand, setbrand] = useState();
  console.log("brand::: ", brand);

  const role = useSelector(selectRole);

  const [maxStoreCount, setStoreCount] = useState(countdata?.store_count || 0);
  //pagination
  // const [Viewtransaction, setViewtransaction] = useState(false);
  const [TransactionTable, setTransactioTable] = useState(true);
  let [totalCount, setTotalCount] = useState(0);
  // console.log('totalCount::: ', totalCount);
  let [currentPage, setCurrentPage] = useState(1);
  let [itemsPerPage, setItemsPerPage] = useState(10);
  let [searchKey, setSearchKey] = useState("");
  let [isLoading, setIsLoading] = useState(true);
  const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);
  const [range, setRange] = useState({
    startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    endDate: new Date(),
  });

  const [isOpen, setIsOpen] = useState(false);
  const handleTogglePicker = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (ranges) => {
    const temp = {
      startDate: ranges["range1"]["startDate"],
      endDate: ranges["range1"]["endDate"],
    };
    setRange(temp);
  };

  const [data, setData] = useState({});
  const navigate = useNavigate();
  const handleShow = () => {
    setCreateForm(true);
    setBrandTable(false);
    setcashproform(false);
  };

  const showPriority = () => {
    setCreateForm(false);
    setBrandTable(false);
    setcashproform(true);
  };

  const back = (list = true) => {
    list && getLocationdetails(id);
    setBrandTable(true);
    setEdiform(false);
    setCreateForm(false);
    setcashproform(false);
    setcashbackview(false);
    setData({});
  };

  const handleShowedit = (brand) => {
    setData(brand);
    // setcashproform(true);
    setEdiform(true);
    setBrandTable(false);
  };

  // const handleView = (brand) => {
  //   setData(brand);
  //   setEdiform(true);
  //   setBrandTable(false);
  // };

  const handleShowView = (brand) => {
    setData(brand);
    setcashbackview(true);
    setBrandTable(false);
  };

  const getLocationdetails = async (id) => {
    try {
      const requestBody = {
        offset: (currentPage - 1) * itemsPerPage,
        limit: itemsPerPage,
        search: searchKey,

        parent_merchant_id: id,
        startDate: range.startDate,
        endDate: range.endDate,
      };
      const resp = await getLocation(requestBody);
      console.log("resp::: ", resp);

      if (resp?.status === 200 && resp?.data?.statusCode === 200) {
        setbrand(resp?.data?.getallbrand);
        setTotalCount(resp?.data?.count);
        setBool(resp?.data['isCreate']);
      } else {
        toast.error(resp?.message || resp?.data?.message);
      }
    } catch (error) {
      if (error?.message) {
        const msg = error?.message?.split(":")?.[1]?.trim();

        console.error(msg);
      }
      console.log(error.message);
    }
  };

  // const getAllBrand = async () => {
  //   const offset = (currentPage - 1) * itemsPerPage;
  //   const offsetToUse = offset >= 0 ? offset : 0
  //   // offset = (currentPage - 1) * itemsPerPage;
  //   try {
  //     const requestBody = {
  //       offset: offsetToUse,
  //       limit: itemsPerPage,
  //       search: searchKey,
  //       startDate: convertToISOString(range.startDate),
  //       endDate: convertToISOString(range.endDate)
  //     };
  //     const resp = await getBrandList(requestBody);

  //     if (resp?.status === 200 && resp?.data?.statusCode === 200) {
  //       setStoreCount(resp?.data?.getallbrand);
  //       // setTotalCount(resp?.data?.count);
  //     } else {
  //       toast.error(resp?.message || resp?.data?.message);
  //     }
  //   } catch (error) {
  //     if (error?.message) {
  //       const msg = error?.message?.split(":")?.[1]?.trim();

  //       console.error(msg);
  //     }
  //     console.log(error.message);
  //   }
  // };

  const [column, setColumn] = useState([
    {
      heading: () => "Action",
      cell: (row) => (
        <img
          src={ViewIcon}
          alt="ViewIcon"
          className="cursorPointer"
          onClick={() => handleShowView(row)}
        />
      ),
      hidden: false,
      width: "120",
      align: "left",
      id: 9,
    },
    {
      heading: () => "Contact Name",
      cell: (row) => (row["contact_name"] ? row["contact_name"] : "-"),
      hidden: false,
      width: "120",
      align: "left",
      id: 3,
    },
    {
      heading: () => "Email",
      cell: (row) => (row["email"] ? row["email"] : "-"),
      hidden: false,
      width: "120",
      align: "left",
      id: 5,
    },
    {
      heading: () => "Location",
      cell: (row) => (row["location"] ? row["location"] : "-"),
      hidden: false,
      width: "120",
      align: "left",
      id: 4,
    },
    {
      heading: () => "Latitude",
      cell: (row) => (row["latitude"] ? row["latitude"] : "-"),
      hidden: false,
      width: "120",
      align: "left",
      id: 7,
    },

    {
      heading: () => "Longitude",
      cell: (row) => (row["longitude"] ? row["longitude"] : "-"),
      hidden: false,
      width: "120",
      align: "left",
      id: 9,
    },
    {
      heading: () => "Status",
      cell: (row) => (
        <span
          className={
            row?.active_flag
              ? "fontWeight600 UFOGreen_text"
              : "fontWeight600 errorClr_text"
          }
        >
          {row["active_flag"] ? "Active" : "Inactive"}
        </span>
      ),
      hidden: false,
      width: "120",
      align: "left",
      id: 10,
    },
  ]);

  useEffect(() => {
    getLocationdetails(id);
    // getAllBrand()
  }, [currentPage, itemsPerPage, searchKey, range, setSearchKey]);

  const handleBack = () => {
    if (createcashproform || cashbackview || createForm) {
      back();
    } else {
      backFunc();
    }
  };

  return (
    <div>
      <div className="mb_24 displayFlex alignItem_center justifyContent_spacebetween BorderBottom_LavenderSyrup pb_16 Mob_flexWrapGap">
        <div className="displayFlex alignItem_center gap12">
          <div>
            <button className="emptyBtn" onClick={handleBack}>
              <img src={ArrowleftIcon} alt="ArrowleftIcon" />
            </button>
          </div>
          <div>
            <h5 className="fontSize20 fontWeight600 blackClr_text">
              {"Location"}
            </h5>
          </div>
        </div>
      </div>
      {!cashbackview && !Ediform && !createForm && (
        <div className="mb_24 displayFlex alignItem_center justifyContent_spacebetween Mob_flexWrapGap">
          <div>
            <h5 className="fontSize16 fontWeight600 blackClr_text">
              Location List
            </h5>
          </div>
          <div className="displayFlex alignItem_center gap12">
            {role == "merchant" ? (
              ""
            ) : !bool ? null : (
              <div>
                <button
                  className="MainButton cyanSky_clr gap8"
                  type="button"
                  onClick={handleShow}
                >
                  <img className="width20px" src={AddIcon} alt="AddIcon" />
                  <span> Create Location </span>
                </button>
              </div>
            )}

            {/* {totalCount >= maxStoreCount ?(
               <div>
               <button
                 className="MainButton cyanSky_clr gap8"
                 type="button"
                 onClick={handleShow}
               
                 
               >
                 <img className="width20px" src={AddIcon} alt="AddIcon" />
                 <span> Create Location </span>
               </button>
             </div>

            ):(
              <>
              </>
            )} */}
            <div>
              <h5 className="fontSize20 fontWeight600 blackClr_text">
                {" "}
                {/* All Transactions List */}
              </h5>
            </div>
            <div className="position_relative">
              <button
                className="lemonPie_clr MainButton gap12"
                onClick={handleTogglePicker}
              >
                <img className="width20px" src={SelectdateIcon} />
                <span>Select date</span>
              </button>
              {isOpen && (
                <div className="DateRangePickerPosition">
                  <DateRangePicker ranges={[range]} onChange={handleSelect} />
                  <button
                    className="closeDateRangePicker"
                    onClick={handleTogglePicker}
                  >
                    <img src={CloseIcon} alt="CloseIcon" />
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {brandTable && (
        <>
          {isLoading ? (
            <PaginatedTable
              row={brand}
              column={column}
              totalCount={totalCount}
              itemsPerPage={itemsPerPage}
              setItemsPerPage={setItemsPerPage}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              isSearch={true}
              searchKey={searchKey}
              setSearchKey={setSearchKey}
              maxPageNumberLimit={maxPageNumberLimit}
              setMaxPageNumberLimit={setMaxPageNumberLimit}
              minPageNumberLimit={minPageNumberLimit}
              setMinPageNumberLimit={setMinPageNumberLimit}
              isExport={false}
            />
          ) : (
            <div style={{ textAlign: "center" }}>Loading...</div>
          )}
        </>
      )}

      {createForm && <CreateLocation data={brand} back={back} />}

      {/* {createcashproform && <CashBackPriority editData={data} back={back} />} */}

      {cashbackview && (
        <ViewLoaction
          brand={data}
          back={back}
          handleShowedit={handleShowedit}
          handleShowView={handleShowView}
        />
      )}
    </div>
  );
};

export default LocationManagement;
