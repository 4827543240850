import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

import { CreateNewCampaign } from "../../services/campaign.service";
import { selectCurrentUser } from "../../store/user/user.selector";
import CreateIcon from "../../assets/images/create_icon.svg";
import { fileUpload } from "../../services/common.service";
import BackIcon from "../../assets/images/back_icon.svg";
import CloseIcon from "../../assets/images/close_icon.svg";
import UploadImg from "../../assets/images/upload_img.svg";
import ArrowleftIcon from "../../assets/images/arrowleft_icon.svg";
import { getCategory } from "../../services/merchant.service";
import { GetMerchantCategory } from "../../services/Category.service";
import { jsx } from "slate-hyperscript";
import { Parser } from "htmlparser2";
import { DomHandler } from "domhandler";
import TextEditor from "../TextEditor/TextEditor";

import Select from "react-select";
import { calculatePercentage } from "../../utils/helpers/helper";

const fileType = "Campaign";

const CreateCampaign = ({ brand = [], back }) => {
  const [selectedMerchant, setSelectedMerchant] = useState("");

  const [Category, setCategory] = useState([]);
  const [Redemption, setRedemption] = useState([
    {
      name: "WEEKLY",
      value: "WEEKLY",
    },
    {
      name: "MONTHLY",
      value: "MONTHLY",
    },
  ]);

  const [selectedOptions, setSelectedOptions] = useState([]);

  const user = useSelector(selectCurrentUser)?.userDetail;

  const id = user?.userType === "brand" ? user?.brand_id : "";

  const today = new Date().toISOString().split("T")[0];

  const [isMultipleRedemption, setIsMultipleRedemption] = useState(false);

  // // Step 2: Create event handlers
  // const handleToggleChange = () => {
  //   setIsMultipleRedemption(!isMultipleRedemption);
  // };

  // const handleCountChange = (event) => {
  //   setRedemptionCount(event.target.value);
  // };

  const endOfCurrentMonth = new Date(
    new Date().getFullYear(),
    new Date().getMonth() + 1,
    0
  )
    .toISOString()
    .split("T")[0];
  // form

  const deserialize = (html) => {
    const parseNode = (node) => {
      if (node.type === "text") {
        let attributes = {};
        if (
          node.parent &&
          (node.parent.name === "strong" ||
            node.parent.name === "em" ||
            node.parent.name === "u")
        ) {
          if (node.parent.name === "strong") attributes.bold = true;
          if (node.parent.name === "em") attributes.italic = true;
          if (node.parent.name === "u") attributes.underline = true;
        }
        return { ...attributes, text: node.data };
      }

      switch (node.name) {
        case "p":
          return {
            type: "paragraph",
            children: node.children ? node.children.map(parseNode) : [],
          };
        case "a":
          return {
            type: "link",
            url: node.attribs.href,
            children: node.children ? node.children.map(parseNode) : [],
          };
        case "blockquote":
          // Assuming all block quotes are wrapped in a paragraph tag
          return {
            type: "block-quote",
            children: [
              {
                type: "paragraph",
                children: node.children ? node.children.map(parseNode) : [],
              },
            ],
          };
        case "ul":
          return {
            type: "bulleted-list",
            children: node.children ? node.children.map(parseNode) : [],
          };
        case "ol":
          return {
            type: "numbered-list",
            children: node.children ? node.children.map(parseNode) : [],
          };
        case "li":
          return {
            type: "list-item",
            children: node.children ? node.children.map(parseNode) : [],
          };
        case "h1":
          return {
            type: "heading-one",
            children: node.children ? node.children.map(parseNode) : [],
          };
        case "h2":
          return {
            type: "heading-two",
            children: node.children ? node.children.map(parseNode) : [],
          };
        case "div":
          // Assuming divs are used for text alignment
          const align = node.attribs.style.split(":")[1].trim();
          return {
            align,
            children: node.children ? node.children.map(parseNode) : [],
          };
        default:
          // Check if the node has only one child
          if (node.children && node.children.length === 1) {
            return parseNode(node.children[0]);
          } else {
            return node.children ? node.children.map(parseNode) : [];
          }
      }
    };

    try {
      const handler = new DomHandler();
      const parser = new Parser(handler);

      parser.write(html || "");
      parser.end();

      const document = handler.dom.map(parseNode);
      return document.length > 0
        ? document
        : [{ type: "paragraph", children: [{ text: "" }] }];
    } catch (error) {
      console.error("Error occurred during deserialization:", error);
      return [
        {
          type: "paragraph",
          children: [{ text: "Error occurred during deserialization" }],
        },
      ];
    }
  };

  const [termsAndCondition, setTermsAndCondition] = useState("");
  const [privacyPolicy, setPrivacyPolicy] = useState("");
  const [stepsToRedeem, setStepsToRedeem] = useState("");

  ///merchant select
  const merchantlist = brand.map((e) => ({
    value: e.id,
    label: e.name,
  }));

  const {
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    values,
    errors,
    touched,
    resetForm,
    setErrors,
  } = useFormik({
    initialValues: {
      CampaignName: "",
      CampaignPromo: "",
      CouponCount: "",
      brand_id: id,
      startDate: "",
      endDate: "",
      offer_type: "Value",
      offer_value: "",
      min_spend: 0,
      upper_limit: "",
      logo: null,
      selectedLogo: null,
      privacyPolicy: "",
      termsAndCondition: "",
      stepsToRedeem: "",
      banner: null,
      category_id: "",
      privacyPolicy_raw: privacyPolicy,
      termsAndCondition_raw: termsAndCondition,
      stepsToRedeem_raw: stepsToRedeem,
      redemption_count: 1,
      redemption_interval: "NIL",
      redemption_type: "perredemption",
    },
    validationSchema: Yup.object({
      CampaignName: Yup.string()
        .matches(
          /^[a-zA-Z0-9\s]*$/,
          "Only alphanumeric characters and spaces are allowed"
        )
        .max(50, "Campaign name must be at most 50 characters")
        .required("Campaign name is required"),
      CampaignPromo: Yup.string()
        .matches(/^[a-zA-Z0-9]*$/, "Only alpha numeric characters are allowed")
        .max(50, "Promo code must be at most 50 characters")
        .required("Promo code is required"),
      logo: Yup.string().required("Logo is required"),
      banner: Yup.string().required("Banner is required"),
      CouponCount: Yup.string()
        .required("Coupon count is required")
        .matches(/^[1-9][0-9]*$/, {
          message: "Coupon count must be a valid number",
          excludeEmptyString: true, // Exclude empty string from matching
        }),
      // redemption_count:Yup.string()
      // .required("Redemption Count is required")
      // .matches(/^[1-9][0-9]*$/, {
      //   message: "Redemption Count must be a valid number",
      //   excludeEmptyString: true, // Exclude empty string from matching
      // }),

      redemption_count:
        isMultipleRedemption &&
        Yup.number()
          .typeError("Value must be a number")
          .positive("Count must be greater than 0")
          .integer("Count must be an integer")
          .required("Count must be required"),
      // brand_id: Yup.string().required("Merchant name is required"),
      startDate: Yup.string().required("Start date is required"),
      endDate: Yup.string().required("End date is required"),
      offer_type: Yup.string().required("offer type is required"),
      offer_value: Yup.string()
        .test({
          name: "offer_value",
          exclusive: false,
          test: function (value, { createError }) {
            const isNumeric = /^[1-9][0-9]*([.][0-9]+)?$|^0[.][0-9]+$/.test(
              value
            );
            if (isNumeric) {
              if (this.parent.offer_type === "Percentage") {
                if (parseFloat(value) < 100) return true; // Reset error
                else
                  return createError({
                    message:
                      "Value must be a number and below 100 for percentage offers",
                  });
              } else {
                if (parseFloat(value) < parseFloat(this.parent.min_spend))
                  return true; // Reset error
                else
                  return createError({
                    message: "Value must be a number and below min spend",
                  });
              }
            } else {
              return true; // Reset error for non-numeric values
            }
          },
        })
        .matches(
          /^\d+(\.\d{1,3})?$/,
          "Min spend must be a positive number with up to three decimal places"
        )
        .required("Value is required"),
      min_spend: Yup.string()
        .required("Min spend  is required")
        .matches(
          /^\d+(\.\d{1,3})?$/,
          "Min spend must be a positive number with up to three decimal places"
        ),
      upper_limit: Yup.string()
        .matches(
          /^\d+(\.\d{1,3})?$/,
          "Upper limit must be a positive number with up to three decimal places"
        )
        .test({
          name: "upper_limit",
          exclusive: false,
          test: function (value, { parent }) {
            // Check if the value is a valid number
            const isNumeric = /^[1-9][0-9]*([.][0-9]+)?$|^0[.][0-9]+$/.test(
              value
            );

            // Skip validation for "Value" offer type or empty value
            if (parent.offer_type === "Value" || !+value) {
              return true;
            }

            // Return false if value is not numeric
            if (!isNumeric) {
              return false;
            }

            // Specific validation for "Percentage" offer type (value must be positive)
            if (parent.offer_type === "Percentage") {
              return parseFloat(value) > 0;
            }

            // Skip validation for other offer types
            return true;
          },
        }),
      privacyPolicy: Yup.string()
        .max(800, "Privacy policy  must be at most 800 characters")
        .required("Privacy policy is required"),
      termsAndCondition: Yup.string()
        .max(800, "Terms and condition  must be at most 800 characters")
        .required("Terms and condition is required"),
      stepsToRedeem: Yup.string()
        .max(800, "Steps to redeem  must be at most 800 characters")
        .required("Steps to redeem required"),
      category_id: Yup.string().required("Category is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      console.log("values::: ", values);
      try {
        const payload = {
          ...values,
          CouponCount: +values.CouponCount,
          min_spend: +values.min_spend,
          upper_limit:
            values?.offer_type === "Percentage" ? +values?.upper_limit : 0,
          discount_value:
            values?.offer_type === "Percentage"
              ? +values?.upper_limit ||
                calculatePercentage(+values?.offer_value, +values.min_spend)
              : +values?.offer_value,
          campaign_logo: values?.logo,
          banner_image: values?.banner,
          category_id: +selectedOptions,
          redemption_count:
            values?.redemption_type == "perredemption"
              ? 1
              : +values?.redemption_count,
          redemption_type: values?.redemption_type,

          // redemption_count: "",

          privacyPolicy_raw: deserialize(values?.privacyPolicy),
          termsAndCondition_raw: deserialize(values?.termsAndCondition),
          stepsToRedeem_raw: deserialize(values?.stepsToRedeem),
        };
        const res = await CreateNewCampaign(payload);

        if (res?.status === 200 && res?.data?.statusCode === 200) {
          toast.success(res.data.message);
          resetForm();
          setErrors({});
          back();
        } else {
          toast.error(res?.message || res?.data?.message);
        }
      } catch (error) {
        if (error?.message) {
          const msg = error?.message?.split(":")?.[1]?.trim();
          console.error(msg);
        }
        console.log(error.message);
      }
    },
  });

  const handleTypeChange = (e) => {
    setSelectedOptions(e.target.value);
  };

  const handleTypeChange1 = (e) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
    setFieldValue("offer_value", "");
  };

  const handleTypeChangemerchantcategory = (e) => {
    setSelectedOptions(e.target.value);
    setFieldValue("category_id", e.target.value);
  };

  const handleFileChange = async (event, type = "logo") => {
    try {
      const selectedFile = event.target.files[0];
      if (selectedFile) {
        const formData = new FormData();
        formData.append("file", selectedFile, selectedFile.name);
        let res = await fileUpload(formData, fileType);
        if (res?.data?.statusCode === 200) {
          if (type === "banner") {
            setFieldValue("banner", res?.data?.data);
          } else {
            setFieldValue("logo", res?.data?.data);
            setFieldValue("selectedLogo", URL.createObjectURL(selectedFile));
          }
        }
      }
    } catch (error) {}
  };

  const removeLogo = (type = "logo") => {
    if (type === "banner") {
      setFieldValue("banner", "");
    } else {
      setFieldValue("logo", "");
      setFieldValue("selectedLogo", "");
    }
  };

  const handleClose = () => {
    resetForm();
    setErrors({});
    back(false);
  };

  const handleMerchantChange = (event) => {
    const test = event.value;
    setFieldValue("brand_id",test)
    setSelectedMerchant(test);
    handleChange(test);
  };

  useEffect(() => {
    if (selectedMerchant || id) {
      const getCategorylist = async () => {
        try {
          const res = await GetMerchantCategory(id ? id : selectedMerchant);

          if (res?.status === 200 && res?.data?.statusCode === 200) {
            if (res?.data?.data) {
              setCategory(
                res?.data?.data?.map((category) => ({
                  label: category.category_name,
                  value: category.category_id,
                }))
              );
            }
          } else {
            toast.error(res?.message || res?.data?.message);
          }
        } catch (error) {
          if (error?.message) {
            const msg = error?.message?.split(":")?.[1]?.trim();
            // alert(msg);
            console.error(msg);
          }
          console.log(error.message);
        }
      };

      getCategorylist(selectedMerchant);
    }
  }, [selectedMerchant]);

  const handleSwitchChange = (e) => {
    const isChecked = e.target.checked;
    console.log("isChecked::: ", isChecked);
    setFieldValue(
      "redemption_type",
      isChecked ? "multirredemption" : "perredemption"
    );
    setIsMultipleRedemption(isChecked);
    if (!isChecked) setFieldValue("redemption_count", 1);
  };

  return (
    <div className="whiteFrame">
      {/* <div className="mb_24 displayFlex alignItem_center justifyContent_spacebetween"> */}
      <div className="displayFlex alignItem_center gap12 mb_24">
        <div>
          <button onClick={handleClose} className="emptyBtn">
            <img src={ArrowleftIcon} alt="ArrowleftIcon" />{" "}
          </button>
        </div>
        <div>
          <h5 className="fontSize20 fontWeight600 blackClr_text">
            Create Campaign
          </h5>
        </div>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="mb_24 col-md-6">
            <label className="fontSize14 fontWeight500 blackClr_text display_block mb_8">
              Campaign Logo
            </label>
            {!values?.selectedLogo && (
              <label htmlFor="selectedLogo" className="uploadImg_btn gap12">
                <img src={UploadImg} alt="UploadImg" />
                <span>Upload Campaign Logo</span>
                <input
                  id="selectedLogo"
                  type="file"
                  name="logo"
                  value={values.logo}
                  accept="image/*" // Accept all image file types
                  onChange={handleFileChange}
                  hidden
                />
              </label>
            )}

            {values?.selectedLogo && (
              <div className="ImgFrame position_relative">
                <img
                  className="brandImgSelectlogo"
                  src={values?.selectedLogo}
                  width={100}
                  height={100}
                  alt="selected logo"
                />
                <div className="imgcloseBtn ">
                  <img src={CloseIcon} alt="CloseIcon" onClick={removeLogo} />
                </div>
              </div>
            )}

            {!values.logo && touched.logo ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.logo}
              </p>
            ) : (
              <></>
            )}
          </div>

          <div className="mb_24 col-md-6">
            <label className="fontSize14 fontWeight500 blackClr_text display_block mb_8">
              Campaign Banner
            </label>
            {!values?.banner && (
              <label htmlFor="banner" className="uploadImg_btn gap12">
                <img src={UploadImg} alt="UploadImg" />
                <span>Upload Campaign banner</span>
                <input
                  id="banner"
                  type="file"
                  name="banner"
                  value={values.banner}
                  accept="image/*" // Accept all image file types
                  onChange={(e) => handleFileChange(e, "banner")}
                  hidden
                />
              </label>
            )}

            {values?.banner && (
              <div className="bannerImgFrame position_relative">
                <img
                  className="UcbImg"
                  src={values?.banner}
                  alt="selected Banner"
                />
                <div className="imgcloseBtn ">
                  <img
                    src={CloseIcon}
                    alt="CloseIcon"
                    onClick={() => removeLogo("banner")}
                  />
                </div>
              </div>
            )}

            {!values.banner && touched.banner ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.banner}
              </p>
            ) : (
              <></>
            )}
          </div>

          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="fn"
            >
              Campaign Name
            </label>
            <input
              className="MainInput"
              type="text"
              placeholder="Enter Campaign Name "
              id="CampaignName"
              name="CampaignName"
              value={values.CampaignName}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {errors.CampaignName && touched.CampaignName ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.CampaignName}
              </p>
            ) : (
              <></>
            )}
          </div>

          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="fn"
            >
              Promo Code
            </label>
            <input
              className="MainInput"
              type="text"
              placeholder="Enter Promo code"
              id="CampaignPromo"
              name="CampaignPromo"
              value={values.CampaignPromo}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {errors.CampaignPromo && touched.CampaignPromo ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.CampaignPromo}
              </p>
            ) : (
              <></>
            )}
          </div>

          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="fn"
            >
              Coupon Count
            </label>
            <input
              className="MainInput"
              type="text"
              placeholder="Enter Coupon Count "
              id="CouponCount"
              name="CouponCount"
              value={values.CouponCount}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {errors.CouponCount && touched.CouponCount ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.CouponCount}
              </p>
            ) : (
              <></>
            )}
          </div>

          {!id && (
            <div className="mb_24 col-md-6">
              <label
                className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
                htmlFor="brand_id"
              >
                Merchant Name
              </label>
              <Select
                className="customSelect selectCategory"
                // id="brand_id"
                // name="brand_id"
                value={merchantlist.find(
                  (option) => option.value === selectedMerchant
                )} // Finds and sets the selected option
                onBlur={handleBlur}
                onChange={handleMerchantChange}
                options={merchantlist} // Use the mapped list here
              >
                {/* <option value="">Select Merchant Name</option>
                {brand.map((count) => (
                  <option key={count.id} value={count.id}>
                    {count.name}
                  </option>
                ))} */}
              </Select>
              {errors.brand_id && touched.brand_id ? (
                <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                  {errors.brand_id}
                </p>
              ) : (
                <></>
              )}
            </div>
          )}

          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="category_id "
            >
              Category Select
            </label>
            <select
              className="selectMainInput"
              id="category_id "
              name="category_id "
              value={selectedOptions}
              onBlur={handleBlur}
              onChange={handleTypeChangemerchantcategory}
            >
              <option value="">Select Category</option>
              {Category.map((count) => (
                <option key={count.value} value={count.value}>
                  {count.label}
                </option>
              ))}
            </select>
            {errors.category_id && touched.category_id ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.category_id}
              </p>
            ) : (
              <></>
            )}
          </div>

          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="email"
            >
              Start Date
            </label>
            <input
              className="MainInput"
              type="date"
              id="startDate"
              name="startDate"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.startDate}
              min={today}
              max="9999-12-31" // Maximum allowed date to restrict to 4-digit years
            />
            {errors.startDate && touched.startDate ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.startDate}
              </p>
            ) : (
              <></>
            )}
          </div>

          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="fn"
            >
              End Date
            </label>
            <input
              className="MainInput"
              type="date"
              id="endDate"
              name="endDate"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.endDate}
              min={values.startDate} // Set minimum date to the selected start date
              max="9999-12-31" // Maximum allowed date to restrict to 4-digit years
              // max={endOfCurrentMonth} // Set maximum date to the end of the current month
            />
            {errors.endDate && touched.endDate ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.endDate}
              </p>
            ) : (
              <></>
            )}
          </div>

          <div className="mb_24 col-md-6 mob_mb24">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="toggleValueInput"
            >
              Offer type
            </label>
            <div className="displayFlex alignItem_center gap12">
              <div className="prefer_method">
                <input
                  type="radio"
                  id="offer_value"
                  name="offer_type"
                  value="Value"
                  checked={values.offer_type === "Value"}
                  onChange={(e) => handleTypeChange1(e)}
                />
                <label htmlFor="offer_value">Amount</label>
              </div>
              <div className="prefer_method">
                <input
                  type="radio"
                  id="offer_percentage"
                  name="offer_type"
                  value="Percentage"
                  checked={values.offer_type === "Percentage"}
                  onChange={(e) => handleTypeChange1(e)}
                />
                <label htmlFor="offer_percentage">Percentage</label>
              </div>
            </div>
            {errors.offer_type && touched.offer_type ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.offer_type}
              </p>
            ) : (
              <></>
            )}
          </div>

          <div className="mb_24 col-md-6 mob_mb24">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_16"
              htmlFor="email"
            >
              Minimum spend
            </label>
            <input
              className="MainInput"
              type="text"
              placeholder="Enter Value"
              id="min_spend"
              name="min_spend"
              value={values.min_spend}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {errors.min_spend && touched.min_spend ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.min_spend}
              </p>
            ) : (
              <></>
            )}
          </div>

          <div className="mb_24 col-md-6 mob_mb24">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="email"
            >
              {values?.offer_type === "Value" ? "Amount" : "Percentage"}
            </label>
            <input
              className="MainInput"
              type="text"
              placeholder="Enter Value"
              id="offer_value"
              name="offer_value"
              value={values.offer_value}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {errors.offer_value && touched.offer_value ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.offer_value}
              </p>
            ) : (
              <></>
            )}
          </div>

          {values.offer_type === "Percentage" && (
            <div className="mb_24 col-md-6">
              <label
                className="fontSize14 fontWeight500 blackClr_text display_block mb_16"
                htmlFor="email"
              >
                Upper limit
              </label>
              <input
                className="MainInput"
                type="text"
                placeholder="Enter Value"
                id="upper_limit"
                name="upper_limit"
                value={values.upper_limit}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {errors.upper_limit && touched.upper_limit ? (
                <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                  {errors.upper_limit}
                </p>
              ) : (
                <></>
              )}
            </div>
          )}

          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="redemption_interval"
            >
              Redemption Interval
            </label>
            <select
              className="selectMainInput"
              id="redemption_interval"
              name="redemption_interval"
              value={values.redemption_interval}
              onBlur={handleBlur}
              onChange={(e) =>
                setFieldValue("redemption_interval", e.target.value)
              }
            >
              <option value="NIL">Select Interval</option>
              <option key="WEEKLY" value="WEEKLY">
                WEEKLY
              </option>
              <option key="MONTHLY" value="MONTHLY">
                MONTHLY
              </option>
            </select>
          </div>
          <div className="mb_24 col-md-6">
            <div className="displayFlex justifyContent_spacebetween alignItem_end">
              <div className="">
                <div>
                  <label className="fontSize14 fontWeight500 blackClr_text display_block mb_12">
                    Per Person Redemption
                  </label>
                </div>
                <div>
                  <label className="switch">
                    <input
                      className="switch-input"
                      type="checkbox"
                      checked={values.multiple_redemption}
                      onChange={handleSwitchChange}
                    />
                    <span
                      className="switch-label"
                      data-on="Multiple Time"
                      data-off="Single Time"
                    />
                    {/* {values.multiple_redemption ? 'Multiple Time' : 'Single Time'} */}
                    <span className="switch-handle" />
                  </label>
                </div>
              </div>
              <div>
                {isMultipleRedemption && (
                  <input
                    type="text"
                    className="MainInput"
                    value={values.redemption_count}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="redemption_count"
                    min="0"
                    maxLength={5}
                  />
                )}
                {isMultipleRedemption &&
                errors.redemption_count &&
                touched.redemption_count ? (
                  <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                    {errors.redemption_count}
                  </p>
                ) : null}
              </div>
            </div>

            {/* 
        <div className="toggleContainer">
          <button
            type="button"
            className={`toggleButton ${values.multiple_redemption ? 'active' : ''} switch-label`}
            onClick={() => {
              setFieldValue('multiple_redemption', !values.multiple_redemption);
              setIsMultipleRedemption(!values.multiple_redemption);
            }}
          >
            {values.multiple_redemption ? 'Multiple Time' : 'Single Time'}
          </button>
        </div> */}
          </div>

          <div className="mb_24 col-md-12">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="email"
            >
              Privacy Policy
            </label>
            {/* <textarea
              className="MainTextarea"
              placeholder="Privacy Policy"
              id="privacyPolicy"
              name="privacyPolicy"
              value={values.privacyPolicy}
              onBlur={handleBlur}
              onChange={handleChange}
              rows={3}
            ></textarea> */}
            <TextEditor
              id="privacyPolicy"
              name="privacyPolicy"
              fieldName="privacyPolicy"
              value={privacyPolicy}
              onChange={setPrivacyPolicy}
              setFieldValue={setFieldValue}
              onBlur={handleBlur}
            />

            {errors.privacyPolicy && touched.privacyPolicy ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.privacyPolicy}
              </p>
            ) : (
              <></>
            )}
          </div>
          <div className="mb_24 col-md-12">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="email"
            >
              Terms And Condition
            </label>
            {/* <textarea
              className="MainTextarea"
              placeholder="Terms And Condition"
              id="termsAndCondition"
              name="termsAndCondition"
              value={values.termsAndCondition}
              onBlur={handleBlur}
              onChange={handleChange}
              rows={3}
            ></textarea> */}
            <TextEditor
              id="termsAndCondition"
              name="termsAndCondition"
              fieldName="termsAndCondition"
              value={termsAndCondition}
              onChange={setTermsAndCondition}
              setFieldValue={setFieldValue}
              onBlur={handleBlur}
            />

            {errors.termsAndCondition && touched.termsAndCondition ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.termsAndCondition}
              </p>
            ) : (
              <></>
            )}
          </div>
          <div className="mb_24 col-md-12">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="email"
            >
              Steps To Redeem
            </label>
            {/* <textarea
              className="MainTextarea"
              placeholder="Steps To Redeem"
              id="stepsToRedeem"
              name="stepsToRedeem"
              value={values.stepsToRedeem}
              onBlur={handleBlur}
              onChange={handleChange}
              rows={3}
            ></textarea> */}
            <TextEditor
              id="stepsToRedeem"
              name="stepsToRedeem"
              fieldName="stepsToRedeem"
              value={stepsToRedeem}
              onChange={setStepsToRedeem}
              setFieldValue={setFieldValue}
              onBlur={handleBlur}
            />
            {errors.stepsToRedeem && touched.stepsToRedeem ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.stepsToRedeem}
              </p>
            ) : (
              <></>
            )}
          </div>
        </div>

        {/* ========= Submit Button ========= */}
        <button type="submit" className="MainButton cyanSky_clr gap12">
          <img src={CreateIcon} alt="CreateIcon" className="width_20px" />
          <span>Save Draft</span>
        </button>
      </form>
    </div>
  );
};

export default CreateCampaign;
