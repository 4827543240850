import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";

import { EditCampaignDetail } from "../../services/campaign.service";
import SaveIcon from "../../assets/images/save_icon.svg";
import CloseIcon from "../../assets/images/close_icon.svg";
import UploadImg from "../../assets/images/upload_img.svg";
import BackIcon from "../../assets/images/back_icon.svg";
import { fileUpload } from "../../services/common.service";
import ArrowleftIcon from "../../assets/images/arrowleft_icon.svg";
import { GetMerchantCategory } from "../../services/Category.service";
import { jsx } from "slate-hyperscript";
import { Parser } from "htmlparser2";
import { DomHandler } from "domhandler";
import TextEditor from "../TextEditor/TextEditor";
import { calculatePercentage } from "../../utils/helpers/helper";
import Select from "react-select";

// import TextEditor from "../TextEditor/TextEditor";

const fileType = "Campaign";
const EditCampaign = ({ data, brand = {}, back }) => {
  const deserialize = (html) => {
    const parseNode = (node) => {
      if (node.type === "text") {
        let attributes = {};
        if (
          node.parent &&
          (node.parent.name === "strong" ||
            node.parent.name === "em" ||
            node.parent.name === "u")
        ) {
          if (node.parent.name === "strong") attributes.bold = true;
          if (node.parent.name === "em") attributes.italic = true;
          if (node.parent.name === "u") attributes.underline = true;
        }
        return { ...attributes, text: node.data };
      }

      switch (node.name) {
        case "p":
          return {
            type: "paragraph",
            children: node.children ? node.children.map(parseNode) : [],
          };
        case "a":
          return {
            type: "link",
            url: node.attribs.href,
            children: node.children ? node.children.map(parseNode) : [],
          };
        case "blockquote":
          // Assuming all block quotes are wrapped in a paragraph tag
          return {
            type: "block-quote",
            children: [
              {
                type: "paragraph",
                children: node.children ? node.children.map(parseNode) : [],
              },
            ],
          };
        case "ul":
          return {
            type: "bulleted-list",
            children: node.children ? node.children.map(parseNode) : [],
          };
        case "ol":
          return {
            type: "numbered-list",
            children: node.children ? node.children.map(parseNode) : [],
          };
        case "li":
          return {
            type: "list-item",
            children: node.children ? node.children.map(parseNode) : [],
          };
        case "h1":
          return {
            type: "heading-one",
            children: node.children ? node.children.map(parseNode) : [],
          };
        case "h2":
          return {
            type: "heading-two",
            children: node.children ? node.children.map(parseNode) : [],
          };
        case "div":
          // Assuming divs are used for text alignment
          const align = node.attribs.style.split(":")[1].trim();
          return {
            align,
            children: node.children ? node.children.map(parseNode) : [],
          };
        default:
          // Check if the node has only one child
          if (node.children && node.children.length === 1) {
            return parseNode(node.children[0]);
          } else {
            return node.children ? node.children.map(parseNode) : [];
          }
      }
    };

    try {
      const handler = new DomHandler();
      const parser = new Parser(handler);

      parser.write(html || "");
      parser.end();

      const document = handler.dom.map(parseNode);
      return document.length > 0
        ? document
        : [{ type: "paragraph", children: [{ text: "" }] }];
    } catch (error) {
      console.error("Error occurred during deserialization:", error);
      return [
        {
          type: "paragraph",
          children: [{ text: "Error occurred during deserialization" }],
        },
      ];
    }
  };

  const [selectedMerchant, setSelectedMerchant] = useState(
    data?.brand_id || ""
  );

  const handleTypeChange3 = (e) => {
    setSelectedMerchant(e.value);
    setFieldValue("brand_id", e.value);
  };

  const [Category, setCategory] = useState([]);

  const [selectedOptions, setSelectedOptions] = useState([]);

  const initialRedemptionType = data?.redemption_type === "multirredemption";
  const [isMultipleRedemption, setIsMultipleRedemption] = useState(
    initialRedemptionType
  );

  // const privacyPolicy = deserialize(data?.privacy_policy) || "";
  const [privacyPolicy, setPrivacyPolicy] = useState(
    data?.privacy_policy || ""
  );
  const [termsAndCondition, setTermsAndCondition] = useState(
    data?.terms_condition || ""
  );
  const [stepsToRedeem, setStepsToRedeem] = useState(
    data?.step_to_redeem || ""
  );

  const handleClose = () => {
    resetForm();
    setErrors({});
    back(false);
  };

  const today = new Date().toISOString().split("T")[0];
  const formattedToday = new Date().toISOString().split("T")[0];

  const {
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    values,
    errors,
    touched,
    resetForm,
    setErrors,
    submitCount,
  } = useFormik({
    initialValues: {
      CampaignName: data.campaign_name,
      CampaignPromo: data.campaign_promo,
      CouponCount: data.coupon_count,
      startDate: new Date(data.start_date).toISOString().split("T")[0],
      endDate: new Date(data.end_date).toISOString().split("T")[0],
      offer_type: data.offer_type,
      offer_value: data.offer_value,
      min_spend: data.min_spend,
      upper_limit: data.upper_limit || "",
      logo: data?.campaign_logo,
      banner: data?.banner_image,
      selectedLogo: data?.campaign_logo,
      privacyPolicy: data?.privacy_policy,
      termsAndCondition: data?.terms_condition,
      stepsToRedeem: data?.step_to_redeem,
      privacyPolicy_raw: data?.privacy_policy_raw,
      termsAndCondition_raw: data?.terms_condition_raw,
      stepsToRedeem_raw: data?.step_to_redeem_raw,
      brand_id: data?.brand_id,
      category_id: data?.category_id,
      redemption_count: data?.redemption_count,
      redemption_type: data?.redemption_type,
      redemption_interval: data?.redemption_interval 
    },

    enableReinitialize: true,
    validationSchema: Yup.object({
      CampaignName: Yup.string()
        .max(50, "Campaign name must be at most 50 characters")
        .required("Campaign name is required"),
      CampaignPromo: Yup.string()
        .max(50, "Promo code must be at most 50 characters")
        .required("Promo code is required"),
      logo: Yup.string().required("logo is required"),
      banner: Yup.string().required("banner is required"),
      CouponCount: Yup.string()
        .required("Coupon count is required")
        .matches(/^[1-9][0-9]*$/, "Coupon count must be a number"),
      brand_id: Yup.string().required("Merchant is required"),
      startDate: Yup.string().required("Start date is required"),
      endDate: Yup.string().required("End date is required"),
      offer_type: Yup.string().required("offer type is required"),
      offer_value: Yup.string()
        .test({
          name: "offer_value",
          exclusive: false,
          test: function (value, { createError }) {
            const isNumeric = /^[1-9][0-9]*([.][0-9]+)?$|^0[.][0-9]+$/.test(
              value
            );
            if (isNumeric) {
              if (this.parent.offer_type === "Percentage") {
                if (parseFloat(value) < 100) return true; // Reset error
                else
                  return createError({
                    message:
                      "Value must be a number and below 100 for percentage offers",
                  });
              } else {
                if (parseFloat(value) < parseFloat(this.parent.min_spend))
                  return true; // Reset error
                else
                  return createError({
                    message: "Value must be a number and below min spend",
                  });
              }
            } else {
              return true; // Reset error for non-numeric values
            }
          },
        })
        .matches(
          /^\d+(\.\d{1,3})?$/,
          "Min spend must be a positive number with up to three decimal places"
        )
        .required("Value is required"),
      min_spend: Yup.string()
        .required("Min spend  is required")
        .matches(
          /^\d+(\.\d{1,3})?$/,
          "Min spend must be a positive number with up to three decimal places"
        ),
      upper_limit: Yup.string()
        .matches(
          /^\d+(\.\d{1,3})?$/,
          "Upper limit must be a positive number with up to three decimal places"
        )
        .test({
          name: "upper_limit",
          exclusive: false,
          test: function (value, { parent }) {
            // Check if the value is a valid number
            const isNumeric = /^[1-9][0-9]*([.][0-9]+)?$|^0[.][0-9]+$/.test(
              value
            );

            // Skip validation for "Value" offer type or empty value
            if (parent.offer_type === "Value" || !+value) {
              return true;
            }

            // Return false if value is not numeric
            if (!isNumeric) {
              return false;
            }

            // Specific validation for "Percentage" offer type (value must be positive)
            if (parent.offer_type === "Percentage") {
              return parseFloat(value) > 0;
            }

            // Skip validation for other offer types
            return true;
          },
        }),
      privacyPolicy: Yup.string()
        .max(800, "Privacy policy  must be at most 800 characters")
        .required("Privacy policyis required"),
      termsAndCondition: Yup.string()
        .max(800, "Terms and condition  must be at most 800 characters")
        .required("Terms and condition is required"),
      stepsToRedeem: Yup.string()
        .max(800, "Steps to redeem must be at most 800 characters")
        .required("Steps to redeem required"),
      redemption_count:
        isMultipleRedemption &&
        Yup.number()
          .typeError("Value must be a number")
          .positive("Count must be greater than 0")
          .integer("Count must be an integer")
          .required("Count must be required"),

      category_id: Yup.string().required("Category is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        const payload = {
          ...values,
          CouponCount: +values.CouponCount,
          id: data.id,
          min_spend: +values.min_spend,
          upper_limit:
            values?.offer_type === "Percentage" ? +values?.upper_limit : 0,
          discount_value:
            values?.offer_type === "Percentage"
              ? +values?.upper_limit ||
                calculatePercentage(+values?.offer_value, +values.min_spend)
              : +values?.offer_value,
          campaign_logo: values?.logo,
          banner_image: values?.banner,
          category_id: +values?.category_id,
          privacyPolicy_raw: deserialize(values?.privacyPolicy),
          termsAndCondition_raw: deserialize(values?.termsAndCondition),
          stepsToRedeem_raw: deserialize(values?.stepsToRedeem),
          redemption_count:
            values?.redemption_type == "perredemption"
              ? 1
              : +values?.redemption_count,
          redemption_type: values.redemption_type,
        };
        console.log("payload", payload);
        console.log("values", values);

        const res = await EditCampaignDetail(payload);

        if (res?.status === 200 && res?.data?.statusCode === 200) {
          toast.success(res.data.message);
          resetForm();
          back();
        } else {
          toast.error(res?.message || res?.data?.message);
        }
      } catch (error) {
        if (error?.message) {
          const msg = error?.message?.split(":")?.[1]?.trim();
          console.error(msg);
        }
        console.log(error.message);
      }
    },
  });

  const handleTypeChange = (e) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
  };

  const handleFileChange = async (event, type = "logo") => {
    try {
      const selectedFile = event.target.files[0];
      if (selectedFile) {
        const formData = new FormData();
        formData.append("file", selectedFile, selectedFile.name);
        let res = await fileUpload(formData, fileType);
        if (res?.data?.statusCode === 200) {
          if (type === "banner") {
            setFieldValue("banner", res?.data?.data);
          } else {
            setFieldValue("logo", res?.data?.data);
            setFieldValue("selectedLogo", URL.createObjectURL(selectedFile));
          }
        }
      }
    } catch (error) {}
  };

  const removeLogo = (type = "logo") => {
    if (type === "banner") {
      setFieldValue("banner", "");
    } else {
      setFieldValue("logo", "");
      setFieldValue("selectedLogo", "");
    }
  };

  const handleTypeChangemerchantcategory = (e) => {
    setSelectedOptions(e.target.value);
    setFieldValue("category_id", e.target.value);
  };



  const handleMerchantChange = (event) => {
    setFieldValue("category_id", "");
    const { value } = event.target;

    setSelectedMerchant(value);
    handleChange(event);
  };

  const handleSwitchChange = (e) => {
    const isChecked = e.target.checked;
    console.log("isChecked::: ", isChecked);
    setFieldValue(
      "redemption_type",
      isChecked ? "multirredemption" : "perredemption"
    );
    setIsMultipleRedemption(isChecked);
    if (!isChecked) setFieldValue("redemption_count", 1);
  };

  useEffect(() => {
    if (selectedMerchant) {
      const getCategorylist = async () => {
        try {
          const res = await GetMerchantCategory(selectedMerchant);

          if (res?.status === 200 && res?.data?.statusCode === 200) {
            if (res?.data?.data) {
              setCategory(res?.data?.data);
            }
          } else {
            toast.error(res?.message || res?.data?.message);
          }
        } catch (error) {
          if (error?.message) {
            const msg = error?.message?.split(":")?.[1]?.trim();
            // alert(msg);
            console.error(msg);
          }
          console.log(error.message);
        }
      };

      getCategorylist(selectedMerchant);
    }
  }, [selectedMerchant]);

  return (
    <div>
    <div className="mb_24 displayFlex alignItem_center justifyContent_spacebetween">
      <div className="displayFlex alignItem_center  gap12">
        {/* <div>
          <button onClick={() => back(false)} className="emptyBtn"><img src={ArrowleftIcon} /></button>
        </div> */}
        {/* <div>
          <h2 className="fontSize20 fontWeight600 blackClr_text">Edit Campaign</h2>
        </div> */}
      </div>
      {/* <div>
                  <h5 className="fontSize20 fontWeight600 blackClr_text">View Campaign</h5>
              </div> */}
      <div className="displayFlex justifyContent_end gap12">
        {/* 
        <div>
          <button
            className="MainButton neverything_clr gap8"
            type="button"
            onClick={() => back(false)}
          >
            <img src={BackIcon} alt="BackIcon" />
            <span> Back </span>
          </button>
        </div> */}

        {/* <div>
        <button className="emptyBtn" onClick={handleClose}>
          <img src={ArrowleftIcon} alt="ArrowleftIcon" />
        </button>
      </div>
      <div>
        <h5 className="fontSize20 fontWeight600 blackClr_text">Edit Campaign</h5>
      </div> */}
      </div>

      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="mb_24 col-md-6">
            <label className="fontSize14 fontWeight500 blackClr_text display_block mb_8">
              Campaign Logo
            </label>
            {!values?.selectedLogo && (
              <label htmlFor="selectedLogo" className="uploadImg_btn gap12">
                <img src={UploadImg} alt="UploadImg" />
                <span>Upload Campaign Logo</span>
                <input
                  id="selectedLogo"
                  type="file"
                  name="logo"
                  value={values.logo}
                  accept="image/*" // Accept all image file types
                  onChange={handleFileChange}
                  hidden
                />
              </label>
            )}

            {values?.selectedLogo && (
              <div className="ImgFrame position_relative">
                <img
                  className="brandImgSelectlogo"
                  src={values?.selectedLogo}
                  width={100}
                  height={100}
                  alt="selected logo"
                />
                <div className="imgcloseBtn ">
                  <img src={CloseIcon} alt="CloseIcon" onClick={removeLogo} />
                </div>
              </div>
            )}

            {!values.logo && touched.logo ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.logo}
              </p>
            ) : (
              <></>
            )}
            
           
          </div>

          <div className="mb_24 col-md-6">
            <label className="fontSize14 fontWeight500 blackClr_text display_block mb_8">
              Campaign Banner
            </label>
            {!values?.banner && (
              <label htmlFor="banner" className="uploadImg_btn gap12">
                <img src={UploadImg} alt="UploadImg" />
                <span>Upload Campaign banner</span>
                <input
                  id="banner"
                  type="file"
                  name="banner"
                  value={values.banner}
                  accept="image/*" // Accept all image file types
                  onChange={(e) => handleFileChange(e, "banner")}
                  hidden
                />
              </label>
            )}

            {values?.banner && (
              <div className="bannerImgFrame position_relative">
                <img
                  className="UcbImg"
                  src={values?.banner}
                  width={100}
                  height={100}
                  alt="selected Banner"
                />
                <div className="imgcloseBtn ">
                  <img
                    src={CloseIcon}
                    alt="CloseIcon"
                    onClick={() => removeLogo("banner")}
                  />
                </div>
              </div>
            )}

            {!values.banner && touched.banner ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.banner}
              </p>
            ) : (
              <></>
            )}
          </div>

          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="fn"
            >
              Campaign Name
            </label>
            <input
              className="MainInput"
              type="text"
              placeholder="Enter Campaign Name"
              id="CampaignName"
              name="CampaignName"
              value={values.CampaignName}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {errors.CampaignName && touched.CampaignName ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.CampaignName}
              </p>
            ) : (
              <></>
            )}
          </div>

          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="fn"
            >
              Promo Code
            </label>
            <input
              className="MainInput"
              type="text"
              placeholder="Enter Promo Code"
              id="CampaignPromo"
              name="CampaignPromo"
              value={values.CampaignPromo}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {errors.CampaignPromo && touched.CampaignPromo ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.CampaignPromo}
              </p>
            ) : (
              <></>
            )}
          </div>

          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="fn"
            >
              Coupon Count
            </label>
            <input
              className="MainInput"
              type="text"
              placeholder="Enter Coupon Count"
              id="CouponCount"
              name="CouponCount"
              value={values.CouponCount}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {errors.CouponCount && touched.CouponCount ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.CouponCount}
              </p>
            ) : (
              <></>
            )}
          </div>

          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="email"
            >
              Start Date
            </label>
            <input
              className="MainInput"
              type="date"
              id="startDate"
              name="startDate"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.startDate}
              min={today}
              max="9999-12-31"
            />
            {errors.startDate ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.startDate}
              </p>
            ) : (
              <></>
            )}
          </div>

          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="fn"
            >
              End Date
            </label>
            <input
              className="MainInput"
              type="date"
              id="endDate"
              name="endDate"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.endDate}
              min={values.startDate}
              max="9999-12-31" // Set minimum date to the selected start date
            />
            {errors.endDate && touched.endDate ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.endDate}
              </p>
            ) : (
              <></>
            )}
          </div>

          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="toggleValueInput"
            >
              Offer type
            </label>
            <div className="displayFlex alignItem_center gap12">
              <div className="prefer_method">
                <input
                  type="radio"
                  id="offer_type_Value"
                  name="offer_type"
                  value="Value"
                  checked={values.offer_type === "Value"}
                  onChange={(e) => handleTypeChange(e)}
                />
                <label htmlFor="offer_type_Value">Amount</label>
              </div>
              <div className="prefer_method">
                <input
                  type="radio"
                  id="offer_type_Percentage"
                  name="offer_type"
                  value="Percentage"
                  checked={values.offer_type === "Percentage"}
                  onChange={(e) => handleTypeChange(e)}
                />
                <label htmlFor="offer_type_Percentage">Percentage</label>
              </div>
            </div>
            {errors.offer_type && touched.offer_type ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.offer_type}
              </p>
            ) : (
              <></>
            )}
          </div>

          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="email"
            >
              Minimum spend
            </label>
            <input
              className="MainInput"
              type="text"
              placeholder="Enter Value"
              id="min_spend"
              name="min_spend"
              value={values.min_spend}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {errors.min_spend && touched.min_spend ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.min_spend}
              </p>
            ) : (
              <></>
            )}
          </div>

          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="email"
            >
              {values?.offer_type === "Value" ? "Amount" : "Percentage"}
            </label>
            <input
              className="MainInput"
              type="text"
              placeholder="Enter Value"
              id="offer_value"
              name="offer_value"
              value={values.offer_value}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {errors.offer_value && touched.offer_value ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.offer_value}
              </p>
            ) : (
              <></>
            )}
          </div>

          {values.offer_type === "Percentage" && (
            <div className="mb_24 col-md-6">
              <label
                className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
                htmlFor="email"
              >
                Upper limit
              </label>
              <input
                className="MainInput"
                type="text"
                placeholder="Enter Value"
                id="upper_limit"
                name="upper_limit"
                value={values.upper_limit}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {errors.upper_limit && touched.upper_limit ? (
                <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                  {errors.upper_limit}
                </p>
              ) : (
                <></>
              )}
            </div>
          )}

          {/* {!id && ( */}
          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="brand_id"
            >
              Merchant
            </label>
            <select
              className="selectMainInput"
              id="brand_id"
              name="brand_id"
              value={values.brand_id}
              onBlur={handleBlur}
              onChange={handleMerchantChange}
            >
              <option value="">Select Merchant</option>
              {brand.map((count) => (
                <option key={count.id} value={count.id}>
                  {count.name}
                </option>
              ))}
            </select>
            {errors.brand_id && touched.brand_id ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.brand_id}
              </p>
            ) : (
              <></>
            )}
          </div>
          {/* )} */}

          {/* {!id && ( */}
          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="category_id "
            >
              Category Select
            </label>
            <select
              className="selectMainInput"
              id="category_id "
              name="category_id "
              value={values.category_id}
              onBlur={handleBlur}
              onChange={handleTypeChangemerchantcategory}
            >
              <option value="">Select Category</option>
              {Category.map((count, i) => (
                <option key={i} value={count.category_id}>
                  {count.category_name}
                </option>
              ))}
            </select>
            {errors.category_id && touched.category_id ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.category_id}
              </p>
            ) : (
              <></>
            )}
          </div>
          {/* )} */}
          {/* {isMultipleRedemption && ( */}
            <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="redmption_interval"
            >
              Redemption Interval
            </label>
            <select
              className="selectMainInput"
              id="redmption_interval"
              name="redmption_interval"
              value={values.redmption_interval}
              onBlur={handleBlur}
              onChange={(e) => setFieldValue("redmption_interval", e.target.value)}
            >
              <option value="NIL">Select Interval</option>
              <option key="WEEKLY" value="WEEKLY">WEEKLY</option>
              <option key="MONTHLY" value="MONTHLY">MONTHLY</option>
            </select>
          </div>
          <div className="mb_24 col-md-6">
              <div className="displayFlex justifyContent_spacebetween alignItem_end">
                <div className="">
                  <div>
                    <label className="fontSize14 fontWeight500 blackClr_text display_block mb_12">
                      Per Person Redemption
                    </label>
                  </div>
                  <div>
                    <label className="switch">
                      <input
                        className="switch-input"
                        type="checkbox"
                        checked={isMultipleRedemption}
                        // checked = "false"
                        onChange={handleSwitchChange}
                      />
                      <span
                        className="switch-label"
                        data-on="Multiple Time"
                        data-off="Single Time"
                      />
                      {/* {values.multiple_redemption ? 'Multiple Time' : 'Single Time'} */}
                      <span className="switch-handle" />
                    </label>
                  </div>
                </div>
                <div>
                  {isMultipleRedemption && (
                    <input
                      type="text"
                      className="MainInput"
                    value={values.redemption_count}
                    onChange={handleChange}
                      onBlur={handleBlur}
                      name="redemption_count"
                      min="0"
                      maxLength={5}
                    />
                  )}
                  {isMultipleRedemption &&
                  errors.redemption_count &&
                  touched.redemption_count ? (
                    <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                      {errors.redemption_count}
                    </p>
                  ) : null}
                </div>
              </div>

              {/* 
      <div className="toggleContainer">
        <button
          type="button"
          className={`toggleButton ${values.multiple_redemption ? 'active' : ''} switch-label`}
          onClick={() => {
            setFieldValue('multiple_redemption', !values.multiple_redemption);
            setIsMultipleRedemption(!values.multiple_redemption);
          }}
        >
          {values.multiple_redemption ? 'Multiple Time' : 'Single Time'}
        </button>
      </div> */}
            </div>
          {/* )} */}

          <div className="mb_24 col-md-12">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="email"
            >
              Privacy Policy
            </label>
            {/* <textarea
              className="MainTextarea"
              placeholder="Privacy Policy"
              id="privacyPolicy"
              name="privacyPolicy"
              value={values.privacyPolicy}
              onBlur={handleBlur}
              onChange={handleChange}
              rows={3}
            ></textarea> */}
            <TextEditor
              name="privacyPolicy"
              value={privacyPolicy}
              values={values.privacyPolicy_raw}
              onChange={(value) => {
                setPrivacyPolicy(value);
                setFieldValue("privacyPolicy", value);
              }}
              // onChange={ setPrivacyPolicy}
              setFieldValue={setFieldValue}
              fieldName="privacyPolicy"
              onBlur={handleBlur}
            />
            {errors.privacyPolicy && touched.privacyPolicy ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.privacyPolicy}
              </p>
            ) : (
              <></>
            )}
          </div>
          <div className="mb_24 col-md-12">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="email"
            >
              Terms And Condition
            </label>
            {/* <textarea
              className="MainTextarea"
              placeholder="Terms And Condition"
              id="termsAndCondition"
              name="termsAndCondition"
              value={values.termsAndCondition}
              onBlur={handleBlur}
              onChange={handleChange}
              rows={3}
            ></textarea> */}
            <TextEditor
              name="termsAndCondition"
              value={termsAndCondition}
              values={values.termsAndCondition_raw}
              onChange={(value) => {
                setTermsAndCondition(value);
                setFieldValue("termsAndCondition", value);
              }}
              setFieldValue={setFieldValue}
              fieldName="termsAndCondition"
            />
            {errors.termsAndCondition && touched.termsAndCondition ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.termsAndCondition}
              </p>
            ) : (
              <></>
            )}
          </div>
          <div className="mb_24 col-md-12">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="email"
            >
              Steps To Redeem
            </label>
            {/* <textarea
              className="MainTextarea"
              placeholder="Steps To Redeem"
              id="stepsToRedeem"
              name="stepsToRedeem"
              value={values.stepsToRedeem}
              onBlur={handleBlur}
              onChange={handleChange}
              rows={3}
            ></textarea> */}
            <TextEditor
              name="stepsToRedeem"
              value={stepsToRedeem}
              values={values.stepsToRedeem_raw}
              onChange={(value) => {
                setStepsToRedeem(value);
                setFieldValue("stepsToRedeem", value);
              }}
              fieldName="stepsToRedeem"
              setFieldValue={setFieldValue}
            />
            {errors.stepsToRedeem && touched.stepsToRedeem ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.stepsToRedeem}
              </p>
            ) : (
              <></>
            )}
          </div>
        </div>

        {/* ========= Submit Button ========= */}
        <button type="submit" className="MainButton cyanSky_clr gap12">
          <img src={SaveIcon} alt="SaveIcon" />
          <span> Save Changes</span>
        </button>
      </form>
    </div>
  </div>
  );
};

export default EditCampaign;
