import { KrtiPayService } from "./api";

const krtiWebUrl = process.env.REACT_APP_WEB_BASE_URL;

export const createLocation = async (req) => {
  try {
    return await KrtiPayService.post(
      `${krtiWebUrl}/location-management/create-location`,
      req
    );
  } catch (error) {
    console.log(error);
    return error?.response?.data;
  }
};
export const getLocation = async (requestBody) => {
  const { offset, limit, user_id, brand_id,search, startDate,endDate,parent_merchant_id  } = requestBody
  try {
    return await KrtiPayService.get(`${krtiWebUrl}/location-management/getAllLocation/${parent_merchant_id}?offset=${offset}&limit=${limit}&search=${search}`, requestBody)
  } catch (error) {
      console.log(error)
      return error?.response?.data
  }
}


export const updatelocation = async (body) => {
  try {
      return await KrtiPayService.patch(`${krtiWebUrl}/location-management/update-location`, body)
  } catch (error) {
      console.log(error)
      return error?.response?.data
  }
}


export const locationstatus = async (payload) => {
  const { id, status } = payload;
  try {
    return await KrtiPayService.post(
      `${krtiWebUrl}/location-management/active-brand-location/${id}/${status}`,
      payload
    );
  } catch (error) {
    console.log(error);
    return error?.response?.data;
  }
};

export const removelocation = async (payload) => {
  const { id } = payload;
  try {
    return await KrtiPayService.delete(
      `${krtiWebUrl}/location-management/remove-brand-location/${id}`
      // body
    );
  } catch (error) {
    console.log(error);
    return error?.response?.data;
  }
};